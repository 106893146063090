import { GetServerSideProps } from 'next'
import { paths } from '@/constants/paths'
import { slugs } from '@/constants/slugs'
import { ProjectMetaProvider } from '@/contexts/ProjectMetaContext'
import { runExperimentOnServer } from '@/experimentation/server'
import { getBasicPageMetaData, Seo } from '@/layout/Seo'
import { buildBrandedCloudinaryImageTransforms } from '@/layout/Seo/SeoHelper'
import { DefaultLayout, NextPageWithLayout } from '@/layouts'
import { getWebClient } from '@/services/ApolloClient'
import { getOffersForProject, Offer } from '@/services/PifService'
import { getProjectMeta, ProjectMeta } from '@/services/ProjectsService'
import { getLocaleFromParams } from '@/utils/LocaleUtil'
import { getUrlFromThisFilePath, getServerSidePropsErrorHandler } from '@/utils/nextUtils/nextErrorHandlers'
import { useTranslate } from '@/utils/translate/translate-client'
import { loadTranslations } from '@/utils/translate/translate-server'
import { PayItForwardExpressCheckoutView } from '@/views/PayItForward/PayItForwardExpressCheckoutView'

interface PayItForwardProjectCheckoutPageProps {
  cloudinaryImageTransformation: string
  locale: string
  offers: Offer[]
  projectMeta: ProjectMeta
  pifVideo: boolean
}

export const PayItForwardProjectCheckoutPage: NextPageWithLayout<PayItForwardProjectCheckoutPageProps> = ({
  cloudinaryImageTransformation,
  locale,
  offers,
  projectMeta,
  pifVideo,
}) => {
  const { t } = useTranslate('pif')

  const projectName = projectMeta.name
  const optionalDescription = t(
    'keepProjectFreeAndHelpCreate',
    'Keep {{projectName}} free and help create future seasons.',
    {
      projectName,
    },
  )

  return (
    <div className="bg-gray-100">
      <Seo
        {...getBasicPageMetaData({
          cloudinaryImagePath: projectMeta.discoveryPosterLandscapeCloudinaryPath,
          cloudinaryTransformation: cloudinaryImageTransformation,
          description: projectMeta.pifExplainerText || optionalDescription,
          locale,
          path: `${paths.payItForward.index}/${projectMeta.slug}`,
          title: t('payItForwardForProject', 'Pay it Forward for {{projectName}} on Angel Studios', { projectName }),
        })}
      />
      <ProjectMetaProvider project={projectMeta}>
        <PayItForwardExpressCheckoutView project={projectMeta} offers={offers} pifVideo={pifVideo} />
      </ProjectMetaProvider>
    </div>
  )
}
export const getServerSideProps: GetServerSideProps = async ({ params, preview, req, res }) =>
  getServerSidePropsErrorHandler({ requestUrl: getUrlFromThisFilePath(params) }, async () => {
    const locale = getLocaleFromParams(params)
    const slug = params?.projectSlug as string
    const client = getWebClient({ locale })
    const projectMeta = await getProjectMeta({ slug }, client, { locale, preview })
    const offers = await getOffersForProject({ projectSlug: slug }, client)

    if (!offers?.length) {
      return {
        redirect: {
          destination: paths.payItForward.forYourConsideration,
          permanent: false,
        },
      }
    }

    // TODO Remove once SOF is out of theaters
    const cloudinaryImageTransformation = buildBrandedCloudinaryImageTransforms({
      logoCloudinaryPath: projectMeta.logoCloudinaryPath,
    })

    const pifVideo =
      projectMeta.slug !== slugs.soundOfFreedom
        ? false
        : await runExperimentOnServer('sound_of_freedom_pif_video', false, req, res)

    return {
      notFound: !projectMeta,
      props: {
        cloudinaryImageTransformation,
        locale,
        offers,
        projectMeta,
        pifVideo,
        ...(await loadTranslations(locale, ['common', 'pif', 'account'])),
      },
    }
  })

PayItForwardProjectCheckoutPage.getLayout = (page) => {
  return <DefaultLayout isDarkMode={false}>{page}</DefaultLayout>
}

export default PayItForwardProjectCheckoutPage
