import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { When } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import { ContentfulRichText } from '@/atoms/ContentfulRichText'
import { FlexColumn } from '@/atoms/FlexContainers'
import { UpArrowCircleIcon } from '@/atoms/Icons/UpArrowCircleIcon'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { AsH2, AsH3, HeadingSM, Text, TitleLG } from '@/atoms/Text'
import { Paragraph } from '@/atoms/Typography'
import { paths, slugs } from '@/constants'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { PayItForwardExplainerSection } from '@/molecules/PayItForwardExplainerSection'
import { PayItForwardOurMission } from '@/molecules/PayItForwardOurMission'
import { BitAngelPlayer } from '@/organisms/BitAngelPlayer'
import { PifVideoPlayerUI } from '@/organisms/BitAngelPlayer/PifVideoPlayerUI'
import { PifExplainerVideoPlayer } from '@/organisms/PifExplainerVideoPlayer'
import { ProjectFundingGoalWidget } from '@/organisms/ProjectFundingGoalDisplay/ProjectFundingGoalWidget'
import { Offer } from '@/services/PifService'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'
import { PayItForwardDefaultPriceExperiment } from './PayItForwardExpressCheckoutContainer/experiments/PayItForwardDefaultPriceExperiment'

interface ProjectPIFData {
  name: string
  slug: string
  pifAvatar: string
  pifExplainerVideoUrl: string
  pifExplainerText: string
  logoCloudinaryPath: string
}

interface ViewProps {
  project: ProjectPIFData
  offers: Offer[]
  pifVideo: boolean
}
export const PayItForwardExpressCheckoutView: FC<ViewProps> = ({ offers, project }) => {
  const lastViewTracked = useRef<string>()
  const [isOfferInView, setOfferInView] = useState<boolean>(false)
  const { t } = useTranslate('pif')
  const pifHeader = t('payItForwardToProjectV2', '{{projectName}}: Pay it Forward', { projectName: project.name })
  const track = useSafeTrack()
  const {
    pifFeaturedOfferId,
    crowdfundingSlug,
    pifCustomInfoSection,
    shouldShowPifExplainerSection = true,
  } = useProjectMeta()
  //TODO clean up after experiment testing pifExplainerVideo
  const sofVideo = 'https://content.angelstudios.com/sof/SOF_Impact_PIF_16x9.mp4'

  useEffect(() => {
    if (lastViewTracked.current === project.slug) return

    track('PIF Project Page Viewed', { projectSlug: project.slug, projectName: project.name })
    lastViewTracked.current = project.slug
  }, [track, project.slug, project.name])

  const handleOfferInViewChange = (inView: boolean) => {
    if (isOfferInView !== inView) {
      setOfferInView(inView)
    }
  }

  const trackVideoStart = useCallback(() => {
    track('Video Content Started', {
      position: 0,
      program: slugs.soundOfFreedom,
      publisher: 'angel-studios',
      title: 'PIF Explainer Video',
      video_type: 'video',
    })
  }, [track])

  const trackVideoCompleted = useCallback(() => {
    track('Video Content Completed', {
      position: 0,
      program: slugs.soundOfFreedom,
      publisher: 'angel-studios',
      title: 'PIF Explainer Video',
      video_type: 'video',
    })
  }, [track])

  const carePortalLinkClicked = () => {
    track('Care Portal Link Clicked')
  }

  const featuredOffer15 = useMemo(() => {
    return offers.find((offer) => offer.price && offer.price === 1500)
  }, [offers])

  const featuredOffer80 = useMemo(() => {
    return offers.find((offer) => offer.price && offer.price === 8000)
  }, [offers])

  const featuredOffer100 = useMemo(() => {
    return offers.find((offer) => offer.price && offer.price === 10000)
  }, [offers])

  //TODO cleanup after experiment is run
  const featuredOffer = pifFeaturedOfferId
    ? offers.find((offer) => offer.id && offer.id === pifFeaturedOfferId)
    : offers[0]

  return (
    <div className="relative">
      <div className="relative top-[64px] flex md:hidden">
        <PayItForwardDefaultPriceExperiment
          project={project}
          offers={offers}
          featuredOffer={featuredOffer}
          featuredOffer15={featuredOffer15}
          featuredOffer80={featuredOffer80}
          featuredOffer100={featuredOffer100}
          handleOfferInViewChange={handleOfferInViewChange}
          showBanner={true}
        />
      </div>
      <PaddedContainer className="w-full py-20 lg:py-28">
        <header className="mb-4">
          <HeadingSM as={AsH2} className="hidden lg:block" weight="bold">
            {pifHeader}
          </HeadingSM>
          <TitleLG weight="bold" as={AsH3} className="hidden md:flex lg:hidden">
            {pifHeader}
          </TitleLG>
        </header>
        <div className="grid w-full gap-16 md:grid-cols-1 lg:grid-cols-[1fr,_390px]">
          <div>
            <div className="flex flex-col">
              <PayItForwardOurMission description={project.pifExplainerText} className="mb-12 md:hidden" />
              <section id="pay-it-forward-explainer-video" className="mb-12 lg:mb-16">
                {slugs.soundOfFreedom === project.slug && (
                  <GBFadeInExperiment defaultValue={false} experimentName="sound_of_freedom_pif_video">
                    <ExperimentVariation variation={true}>
                      <BitAngelPlayer
                        autoplay={false}
                        className="aspect-h-9 aspect-w-16 relative overflow-hidden"
                        ui={PifVideoPlayerUI}
                        url={sofVideo}
                        onStart={trackVideoStart}
                        onPlaybackFinished={trackVideoCompleted}
                      />
                    </ExperimentVariation>
                    <ExperimentVariation variation={false}>
                      <PifExplainerVideoPlayer
                        pifExplainerVideoUrl={project.pifExplainerVideoUrl}
                        name={project.name}
                        slug={project.slug}
                        logoCloudinaryPath={project.logoCloudinaryPath}
                      />
                    </ExperimentVariation>
                    <ExperimentVariation default>
                      <PifExplainerVideoPlayer
                        pifExplainerVideoUrl={project.pifExplainerVideoUrl}
                        name={project.name}
                        slug={project.slug}
                        logoCloudinaryPath={project.logoCloudinaryPath}
                      />
                    </ExperimentVariation>
                  </GBFadeInExperiment>
                )}
                {slugs.soundOfFreedom !== project.slug && (
                  <PifExplainerVideoPlayer
                    pifExplainerVideoUrl={project.pifExplainerVideoUrl}
                    name={project.name}
                    slug={project.slug}
                    logoCloudinaryPath={project.logoCloudinaryPath}
                  />
                )}
              </section>
              <PayItForwardOurMission description={project.pifExplainerText} className="mb-12 hidden md:block" />
            </div>

            {crowdfundingSlug && <ProjectFundingGoalWidget crowdfundingSlug={crowdfundingSlug} />}
            {shouldShowPifExplainerSection && <PayItForwardExplainerSection />}
            <ContentfulRichText className="hidden lg:block" json={pifCustomInfoSection?.json} />
          </div>
          <div className="md:mx-auto md:max-w-[472px] lg:w-[390px]">
            <When condition={project.slug === slugs.soundOfHopeTheStoryOfPossumTrot}>
              <div className={classNames('w-full h flex rounded-2xl border-2 border-white @container mb-2')}>
                <div
                  style={{
                    backgroundImage: `url(${'https://images.angelstudios.com/image/upload/v1723844107/angel-studios/pif/16x9_copy.webp'})`,
                  }}
                  className="relative w-full overflow-hidden rounded-2xl bg-cover bg-center bg-no-repeat px-4 py-6 @2xl:px-8 @2xl:py-20"
                >
                  <div
                    className="absolute inset-0 z-10"
                    style={{
                      background:
                        'linear-gradient(283.3deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.85) 100%)',
                    }}
                  />
                  <FlexColumn className="relative z-20 mr-auto w-full max-w-[211px] gap-3 text-white @2xl:max-w-[400px]">
                    <Text weight="bold" className="photon-heading-xs @2xl:photon-heading-lg w-full">
                      #EndTheFosterCrisis
                    </Text>
                    <Text color="core-gray-200" className="photon-caption-sm @2xl:photon-paragraph-xl">
                      {t(
                        'joinLargestNetworkCaringPeople',
                        'Join the largest network of caring people dedicated to helping children in crisis',
                      )}
                    </Text>
                    <LinkButton
                      external
                      className="mt-2 w-fit text-sm @2xl:text-base"
                      href={paths.external.possumTrotImpact.index ?? ''}
                      variant="white"
                      onClick={carePortalLinkClicked}
                    >
                      {t('joinTheFight', 'Join The Fight')}
                    </LinkButton>
                  </FlexColumn>
                </div>
              </div>
            </When>
            <div className="hidden md:flex">
              <PayItForwardDefaultPriceExperiment
                project={project}
                offers={offers}
                featuredOffer={featuredOffer}
                featuredOffer15={featuredOffer15}
                featuredOffer80={featuredOffer80}
                featuredOffer100={featuredOffer100}
                handleOfferInViewChange={handleOfferInViewChange}
                showBanner={false}
              />
            </div>
            <ContentfulRichText className="lg:hidden" json={pifCustomInfoSection?.json} />
          </div>
        </div>
        <div className="mt-14 lg:hidden">
          <a href="#" className="mx-auto flex flex-col items-center justify-center">
            <UpArrowCircleIcon />
            <Paragraph color="text-gray-500">{t('backToTop', 'Back to top')}</Paragraph>
          </a>
        </div>
      </PaddedContainer>
    </div>
  )
}
