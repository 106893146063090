import React from 'react'
import { setCookie } from 'cookies-next'
import { v4 as uuid } from 'uuid'
import { paths } from '@/constants'
import { Offer } from '@/services/PifService'
import { getPriceForCurrency } from '@/services/PriceFormatter'
import { MoneyCurrencyCode } from '@/types/codegen-federation'
import { useSafeTrack } from '@/utils/analytics'
import { useEcommerceEvents } from '@/utils/analytics/ecommerce-events'
import { DefaultButton } from './DefaultButton'

interface OfferCheckoutButtonProps {
  projectSlug: string
  selectedOffer: Offer | null
  selectedOfferPrice?: string | null
  isSubscription?: boolean
  className?: string
  showPrice?: boolean
}

export const OfferCheckoutButton: React.FC<OfferCheckoutButtonProps> = ({
  selectedOffer,
  projectSlug,
  isSubscription,
  className,
  showPrice,
  selectedOfferPrice,
}) => {
  const track = useSafeTrack()
  const { trackProductAdded } = useEcommerceEvents()

  if (!selectedOffer) return null
  const { id, slug, name, price, currency, region } = selectedOffer

  const trackCheckoutStarted = ({ isSubscription }: { isSubscription: boolean }) => {
    const checkoutId = uuid()
    setCookie('pif_checkout_id', checkoutId)

    const checkoutStartedPayload = {
      category: 'pif',
      project: projectSlug,
      products: [
        {
          product_id: id,
          sku: slug,
          category: 'pif',
          name: name,
          price: price,
          currency: currency,
          region: region,
          quantity: 1,
          url: `${paths.base}${paths.payItForward.index}/${projectSlug}/checkout/${id}`,
        },
      ],
      subscription: isSubscription,
      currency: currency,
      region: region,
      tax: 0,
      shipping: 0,
      potential_revenue: getPriceForCurrency(price, currency),
      checkout_id: checkoutId,
    }
    // TODO: deprecated - remove after release of trackProductAdded and marketing's (Kylo's) blessing
    track('Checkout Started', checkoutStartedPayload)
    // don't deprecate - needed for Optimizely
    track(`Checkout Started ${projectSlug}`, checkoutStartedPayload)

    trackProductAdded({
      funnel: 'pif',
      projectSlug,
      product_id: id,
      sku: slug,
      category: 'pif',
      name,
      price: getPriceForCurrency(price, currency),
      currency: currency as MoneyCurrencyCode,
      quantity: 1,
      url: `${paths.base}${paths.payItForward.index}/${projectSlug}/checkout/${id}`,
    })
  }
  const subscriptionQuery = isSubscription ? '?subscribe=true' : ''

  const offerPath = `${paths.payItForward.index}/${projectSlug}/checkout/${id}${subscriptionQuery}`

  return (
    <DefaultButton
      showPrice={showPrice}
      className={className}
      offerPath={offerPath}
      selectedOfferPrice={selectedOfferPrice}
      handleClick={() => trackCheckoutStarted({ isSubscription: isSubscription || false })}
    />
  )
}
