import React from 'react'
import { CircleDollarFilled } from '@/atoms/Icons/CircleDollarFilled'
import { CircleGiftFilled } from '@/atoms/Icons/CircleGiftFilled'
import { CircleTicketFilled } from '@/atoms/Icons/CircleTicketFilled'
import { AsH3, AsLabel, TitleLG, TitleSM } from '@/atoms/Text'
import { Paragraph, ParagraphSM } from '@/atoms/Typography'
import { MissionExplainerCard } from '@/molecules/MissionExplainerCard'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

export const PayItForwardExplainerSection: React.FC = () => {
  const { t } = useTranslate('pif')
  const fundFuture = t('helpCreateFutureEpisodes', 'Help Create Future Episodes and Seasons')
  const fundFutureDescription = t(
    'fundFutureDescription',
    'Every contribution helps directors, writers, animators, and so many other creators do what they do best without charging exorbitant theater prices or monthly fees.',
  )
  const makeContentFree = t('makeContentFree', 'Make Content Free to Watch')
  const makeContentFreeDescription = t(
    'makeContentFreeDescription',
    'Not only does Pay it Forward benefit you, but it allows you to amplify light around the globe. You can know that your dollars are helping people enjoy wholesome, entertaining content.',
  )

  const gainSpecialPerks = t('gainSpecialPerks', 'Gain Special Perks and Rewards')
  const gainSpecialPerksDescription = t(
    'gainSpecialPerksDescription',
    'You can earn perks based on your level of contribution. Perks are decided by the individual creators. This could be a set visit, your name in the credits, or something special from your favorite show!',
  )
  return (
    <section id="what-is-pay-it-forward">
      <TitleLG className="mb-4" as={AsH3} color="core-gray-950" weight="bold">
        {t('whatIsPIF', 'What is Pay it Forward?')}
      </TitleLG>
      <Paragraph color="text-gray-700" margin="mb-8">
        <Translate t={t} i18nKey="whatIsPIFDescriptionv3">
          Angel Studios’ shows are free for viewers around the world, and we intend to keep it that way. But it takes
          millions of dollars to create and distribute high-quality content. That’s where you come in. Here are a few
          reasons why you play a critical role.
        </Translate>
      </Paragraph>
      <div className="mb-16 grid grid-cols-1 gap-4 md:grid-cols-3">
        <MissionExplainerCard href="#fund-future-episodes" iconComponent={CircleDollarFilled} heading={fundFuture} />
        <MissionExplainerCard href="#make-content-free" iconComponent={CircleTicketFilled} heading={makeContentFree} />
        <MissionExplainerCard href="#gain-special-perks" iconComponent={CircleGiftFilled} heading={gainSpecialPerks} />
      </div>
      <div>
        <section id="fund-future-episodes" className="mb-8">
          <TitleSM className="mb-2" weight="bold" as={AsLabel} color="core-gray-950">
            {fundFuture}
          </TitleSM>
          <ParagraphSM color="text-gray-700">{fundFutureDescription}</ParagraphSM>
        </section>
        <section id="make-content-free" className="mb-8">
          <TitleSM className="mb-2" weight="bold" as={AsLabel} color="core-gray-950">
            {makeContentFree}
          </TitleSM>
          <ParagraphSM color="text-gray-700">{makeContentFreeDescription}</ParagraphSM>
        </section>
        <section id="gain-special-perks" className="mb-8">
          <TitleSM className="mb-2" weight="bold" as={AsLabel} color="core-gray-950">
            {gainSpecialPerks}
          </TitleSM>
          <ParagraphSM color="text-gray-700">{gainSpecialPerksDescription}</ParagraphSM>
        </section>
      </div>
    </section>
  )
}
